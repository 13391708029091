<template>
  <div class="billing">
    <br><br>
    <div class="card">
      <div class="card-table-header">
        <div class="title">Facturación</div>
      </div>
      <div class="card-body">
        <div class="row row-bill" v-if="billingData">
          <div class="col-md-6">
            <div class="billing-box">
              <div class="billing-title">Consumo usuario</div>
              <div class="billing-body">
                <div class="row">
                  <div class="col-md-4">
                    <label>Mes vigente</label>
                    <div class="current-month">{{
                      new
                      Intl.NumberFormat('de-DE', {
                      style: 'currency', currency: 'EUR'
                      }).format(billingData.user.current_month_spend)}}</div>
                  </div>
                  <div class="col-md-8 col-table" style="display: flex; justify-content: end;">
                    <BillingTable :data="billingData.user.months"></BillingTable>
                  </div>
                </div>
                <div>
                  <label>Histórico</label>
                  <Bar v-if="billingData" id="my-chart-id" :options="chartOptions" :data="userChartData" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="billing-box">
              <div class="billing-title"><label>Consumo organización</label>

                <div class="view-select">
                  <div :class="{ 'active': orgView === 'month'}" @click="orgView = 'month'">Mensual</div>
                  <div :class="{ 'active': orgView === 'user'}" @click="orgView = 'user'">Por usuario</div>
                </div>
              </div>
              <div class="billing-body">
                <div class="row row-bill">
                  <div class="col-curr-month">
                    <label>Mes vigente</label>
                    <div class="current-month">{{
                      new
                      Intl.NumberFormat('de-DE', {
                      style: 'currency', currency: 'EUR'
                      }).format(billingData.organization.current_month_spend) }}</div>
                  </div>
                  <div class="col-md-8 col-table">
                    <BillingTable v-if="orgView === 'month'" :data="billingData.organization.months"></BillingTable>
                    <BillingTable v-if="orgView === 'user'" :data="billingData.organization_by_user.users">
                    </BillingTable>
                  </div>
                </div>
                <div>
                  <label>Histórico</label>
                  <Bar v-if="billingData" id="my-chart-id" :options="chartOptions" :data="orgChartData" />
                </div>
              </div>
            </div>
          </div>


        </div>

      </div>
    </div>
    <Loader ref="loading"></Loader>
  </div>
</template>

<script>
import axios from 'axios';
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import Loader from './Loader.vue';
import BillingTable from './BillingTable.vue';
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


export default {
  data() {
    return {
      billingData: null,
      orgView: 'month',
      chartOptions: {
        responsive: true,
        defaultFontColor: 'red',
        border: {
          color: "#3f91a7",

        },
        scales: {
          x: {
            ticks: {
              color: "#3f91a7",
            },
          },
          y: {
            ticks: {
              color: "#3f91a7",
              callback: function (value, index, ticks) {
                return value + ' €';
              },
              font: {
                size: 14,
              }
            },

            grid: {
              color: "#faffff",
            }
          }
        },
        plugins: {
          legend: {
            display: false,
          }
        }
      }
    }
  },
  components: {
    Bar,
    Loader,
    BillingTable
  },
  mounted() {
    this.getBillingData();
  },
  methods: {
    getBillingData() {
      this.$refs.loading.show();
      axios.get('/client-area/billing/history').then(response => {
        this.billingData = response.data.items;
      }).finally(() => {
        this.$refs.loading.hide();
      })
    },

  },
  computed: {
    userChartData() {
      if (!this.billingData) {
        return {
          labels: [],
          datasets: []
        };
      }

      const items = this.billingData.user.months
        .slice(0, 24)
        .reverse()
        .map(item => ({
          label: `${item.month} ${item.year}`,
          value: item.amount_billed_gross
        }));

      return {
        labels: items.map(item => item.label),
        datasets: [{
          data: items.map(item => item.value),
          backgroundColor: '#3d92a7',
          maxBarThickness: 50
        }]
      };
    },
    orgChartData() {
      if (!this.billingData) {
        return {
          labels: [],
          datasets: []
        };
      }

      const items = this.billingData.organization.months
        .slice(0, 24)
        .reverse()
        .map(item => ({
          label: `${item.month} ${item.year}`,
          value: item.amount_billed_gross
        }));
      
      return {
        labels: items.map(item => item.label),
        datasets: [{
          data: items.map(item => item.value),
          backgroundColor: '#3d92a7',
          maxBarThickness: 50
        }]
      };
    }
  }
}
</script>
