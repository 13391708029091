<template>
  <DataTable v-model:filters="filters" size="small"
    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
    currentPageReportTemplate="{currentPage} de  {totalPages}" :value="data" filterDisplay="menu" paginator :rows="5">
    <Column field="year" :showFilterMatchModes="false" header="Año">
      <template #filter="{ filterModel, filterCallback }">
        <select class="form-control form-select-sm" v-model="filterModel.value">
          <option :value="null">-</option>
          <option v-for="year of years" :value="year" @click="filterCallback()">{{ year }}</option>
        </select>
      </template>
    </Column>
    <Column field="month" :showFilterMatchModes="false" header="Mes">
      <template #filter="{ filterModel, filterCallback }">
        <select class="form-control form-select-sm" v-model="filterModel.value">
          <option :value="null">-</option>
          <option v-for="month of months" :value="month" @click="filterCallback()">{{ month }}</option>
        </select>
      </template>
    </Column>
    <Column field="user_name" :showFilterMatchModes="false"
      v-if="data.length > 0 && data[0].hasOwnProperty('user_name')" header="Usuario">
      <template #filter="{ filterModel, filterCallback }">
        <select class="form-control form-select-sm" v-model="filterModel.value">
          <option :value="null">-</option>

          <option v-for="user of users" :value="user" @click="filterCallback()">{{ user }}</option>
        </select>
      </template>
    </Column>
    <Column field="amount_billed_gross" header="Total (€)">
      <template #body="{ data }">
        {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.amount_billed_gross) }}
      </template>
    </Column>
  </DataTable>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';

export default {
  data() {
    return {
      filters: {
        year: {
          value: null,
          matchMode: FilterMatchMode.EQUALS
        },
        month: {
          value: null,
          matchMode: FilterMatchMode.EQUALS
        },
        user_name: {
          value: null,
          matchMode: FilterMatchMode.EQUALS
        }
      }
    }
  },
  props: ['data'],
  components: {
    DataTable,
    Column
  },
  computed: {
    years() {
      return [...new Set(this.data.map(d => d.year))]
    },
    months() {
      return [...new Set(this.data.map(d => d.month))]
    },
    users() {
      return [...new Set(this.data.map(d => d.user_name))]
    }
  }
}
</script>
