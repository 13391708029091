<template>
    <div class="other-budgets" :class="{ 'show': showOtherBudgets }" @click.self="showOtherBudgets = false">
        <div class="card" v-if="currentPartida">
            <div class="card-table-header">
                <div class="title">Otras partidas similares de la base de datos
                    <span class="date">#{{ currentPartida.item_budget_id }}</span>
                    <span class="date" style="max-width: 60%;margin-right: 20px;">
                        {{ currentPartida.item_budget_text }}
                    </span>
                </div>
                <div class="right"><button @click="showOtherBudgets = false" class="btn-secondary btn-close"><i
                            class="icon-close"></i></button></div>
            </div>
            <div class="budget-table">
                <div class="btn-group columns-selector">
                    <button class="btn btn-secondary dropdown-toggle btn-columns float-right" type="button"
                        id="dropdownMenuClickableInside2" data-bs-toggle="dropdown" data-bs-auto-close="outside"
                        aria-expanded="false">
                        <i class="icon-columns"></i>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuClickableInside2">
                        <li v-for="col of similarAvailableCols">
                            <div class="form-check" :class="cols[col].class">
                                <input class="form-check-input" type="checkbox" :value="col"
                                    v-model="similarSelectedCols" :id="col + 'similar'">
                                <label class="form-check-label" :for="col + 'similar'">
                                    {{ cols[col].label }}
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
                <DataTable v-if="otherData && otherData.detail" size="small" dataKey="item_budget_id"
                    :value="otherData.detail" scrollable columnResizeMode="fit"
                    :class="{ 'table-sm': similarSelectedCols.length > 5 || smallScreen }" showGridlines
                    scrollHeight="calc(100vh - 160px)" :virtualScrollerOptions="{ itemSize: similarItemSize }">
                    <Column v-for="col in similarSelectedColsOrdered" :field="col" :header="cols[col].label"
                        :style="'width: ' + ((smallScreen || similarSelectedCols.length > 3) ? cols[col].width.small : cols[col].width.default)"
                        :key="col" :class="cols[col].class">
                        <template #body="slotProps">
                            <template v-if="col === 'item_probability'">
                                <div class="probability" v-if="slotProps.data.item_probability !== '-'">
                                    <span class="num">{{
                                        Math.floor(slotProps.data.item_probability * 100) }}</span><span
                                        :class="`percent percent-${probabilityClass(slotProps.data.item_probability)}`">%</span>
                                </div>
                            </template>
                            <template v-else-if="col === 'item_budget_text' || col === 'item_corpus_description'">
                                <div class="tooltip-bg"><span class="trimmed">{{ slotProps.data[col]
                                        }}<div class="bg"></div></span>
                                    <span class="tooltiptext">{{ slotProps.data[col]
                                        }}</span>
                                </div>
                            </template>
                            <template v-else>
                                <div :title="slotProps.data[col]">{{ slotProps.data[col] }}</div>
                            </template>
                        </template>
                    </Column>
                    <Column field="actions" class="actions-col-other">
                        <template #body="slotProps">
                            <button @click="selectOtherBudget(slotProps.data)"
                                class="btn btn-primary action-button">Seleccionar</button>
                        </template>
                    </Column>
                </DataTable>

            </div>

            <div>


            </div>
        </div>
    </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {
    components: {
        DataTable,
        Column,
    },
    data() {
        return {
            showOtherBudgets: false,
            cols: {
                'item_corpus_id': {
                    class: 'database id-col center',
                    width: {
                        default: 'auto',
                        small: 'auto',
                    },
                    label: 'ID',
                },
                'item_corpus_units': {
                    class: 'database units-col center',
                    width: {
                        default: 'auto',
                        small: 'auto',
                    },
                    label: 'Unidades',
                },
                'item_corpus_description': {
                    class: 'database longtext',
                    width: {
                        default: '50%',
                        small: '50%',
                    },
                    label: 'Descripción',
                },
                
                'item_corpus_price': {
                    class: 'database precio-col center',
                    width: {
                        default: 'auto',
                        small: 'auto',
                    },
                    label: 'Precio',
                },
                'item_corpus_location': {
                    class: 'database ubicacion-col center',
                    width: {
                        default: 'auto',
                        small: 'auto',
                    },
                    label: 'Ubicación',
                },
                'item_corpus_typology': {
                    class: 'database tipologia-col center',
                    width: {
                        default: 'auto',
                        small: 'auto',
                    },
                    label: 'Tipología',
                },
                'item_corpus_medicion': {
                    class: 'database tipologia-col center',
                    width: {
                        default: 'auto',
                        small: 'auto',
                    },
                    label: 'Medición',
                },
                'item_corpus_year': {
                    class: 'database tipologia-col center',
                    width: {
                        default: 'auto',
                        small: 'auto',
                    },
                    label: 'Año',
                },
                'item_probability': {
                    class: 'probabilidad-others-col center',
                    width: {
                        default: '140px',
                        small: '110px',
                    },
                    label: 'Probabilidad',
                },
            },
            similarSelectedCols: [
                'item_corpus_description', 'item_corpus_id', 'item_probability'
            ]
        }
    },

    methods: {
        selectOtherBudget(data) {
            this.showOtherBudgets = false;
            this.$nextTick(() => {
                this.$emit('selectOtherBudget', data);
            })
        },
        show() {
            this.showOtherBudgets = true;
        },
        probabilityClass(probability) {
            if (probability >= 0.8) return 'high';
            if (probability >= 0.6) return 'medium';
            return 'low';
        },
    },
    computed: {
        similarCols() {
            return Object.keys(this.cols);
        },
        similarAvailableCols() {
            if (!this.otherData) {
                return []
            }
            const items = this.otherData.detail;
            if (items.length === 0) {
                return []
            }
            return this.similarCols.filter(key => Object.keys(items[0]).includes(key))
        },
        similarSelectedColsOrdered() {
            return this.similarAvailableCols.filter(key => this.similarSelectedCols.includes(key))
        },
        similarItemSize() {
            const defaultSize = 82;
            const smallScreenSize = this.similarSelectedCols.length > 5 ? 64.39 : 70;
            return this.smallScreen ? smallScreenSize : defaultSize;
        }
    },
    props: ['currentPartida', 'smallScreen', 'otherData']
}
</script>