<template>
  <div class="card card-body card-small-centered">
    <h1>Subir base de datos<a href="" class="budget-help-btn" @click.prevent="showFormatHelp = true">Consultar formatos
        y
        ayuda</a></h1>
    <div v-show="taskId === null && !loading">
      <div :class="{ 'dropzone-disabled': (taskId !== null || loading) }">
        <vue-dropzone v-if="!hideUpload" @vdropzone-upload-progress="handleProgress" ref="myVueDropzone" id="dropzone"
          @vdropzone-success="uploadSuccess" @vdropzone-error="handleError" @vdropzone-file-added="handleFileAdded"
          :useCustomSlot="false" :options="dropzoneOptions">icon</vue-dropzone>
      </div>
      <div class="alert alert-danger mt-3" v-if="validationErrors && validationErrors.file">{{
        validationErrors.file[0]
        }}</div>
      <button v-if="canProcess" @click="startUpload" class="btn btn-primary card-primary-button"
        type="submit">Procesar</button>
    </div>
    <div v-if="(taskId !== null || loading) && progress < 100">
      <i class="icon-cargando icon-loading"></i>
      <div class="progress-container">
        <progress max="100" :value="progress"></progress>
        <div class="progress-num">{{ progress }}%</div>
      </div>
      <p class="text-center text-muted">Procesando BBDD, espera a que haya finalizado</p>
    </div>
    <div v-if="progress === 100">
      <img class="confirm-img mt-3" src="/images/confirm.gif" />
      <div class="text-center mt-3">La base de datos se ha subido con éxito</div>
    </div>
    <div class="side-help" :class="{ 'show': showFormatHelp }" @click.self="showFormatHelp = false">
      <div class="card">
        <div class="card-table-header">
          <div class="title">¡Bienvendio a &nbsp;<b>BUDQUO</b>!
          </div>
          <div class="right"><button @click="showFormatHelp = false" class="btn-secondary btn-close"><i
                class="icon-close"></i></button></div>
        </div>
        <div class="card-body d-flex flex-column">
          <div>
            <p>Para empezar, te invitamos a subir tu base de datos de partidas de obras.</p>
            <div class="info-box formats">
              <div><i class="icon-archivo-csv"></i><i class="icon-archivo-excel"></i></div>
              <div class="desc">Formatos admitidos<div>CSV y EXCEL</div>
              </div>
              <img src="/images/flecha.png">
            </div>
            <p>El archivo debe incluir, como mínimo, <b>una columna con un ID</b> (no duplicado) y <b>una columna con la
                descripción de las partidas</b>.</p>
            <p><i>*Opcionalmente, puede incluir más columnas con la ubicación de la partida (ej. "Barcelona"), columna
                con tipología (ej. "edificio") y/o columnas con precios y unidades.</i>
            </p>
            <div class="table-container">
              <table>
                <thead>
                  <tr>
                    <th class="border-bottom">
                      <span class="color-primary">id</span>
                    </th>
                    <th class="border-left border-bottom">
                      <span class="color-primary">partida</span>
                    </th>
                    <th class="border-left border-bottom">
                      unidades
                    </th>
                    <th class="border-left border-bottom">
                      precio
                    </th>
                    <th class="border-left border-bottom">
                      lugar
                    </th>
                    <th class="border-left border-bottom">
                      tipologia
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border-bottom"></td>
                    <td class="border-left border-bottom">&nbsp;</td>
                    <td class="border-left border-bottom">&nbsp;</td>
                    <td class="border-left border-bottom">&nbsp;</td>
                    <td class="border-left border-bottom">&nbsp;</td>
                    <td class="border-left border-bottom">&nbsp;</td>
                  </tr>
                  <tr>
                    <td class="border-bottom"></td>
                    <td class="border-left border-bottom">&nbsp;</td>
                    <td class="border-left border-bottom">&nbsp;</td>
                    <td class="border-left border-bottom">&nbsp;</td>
                    <td class="border-left border-bottom">&nbsp;</td>
                    <td class="border-left border-bottom">&nbsp;</td>
                  </tr>
                  <tr>
                    <td class="border-bottom"></td>
                    <td class="border-left border-bottom">&nbsp;</td>
                    <td class="border-left border-bottom">&nbsp;</td>
                    <td class="border-left border-bottom">&nbsp;</td>
                    <td class="border-left border-bottom">&nbsp;</td>
                    <td class="border-left border-bottom">&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>Las <span class="color-primary">columnas obligatorias</span> deben nombrarse como <span
                class="color-primary">"id"</span> y <span class="color-primary">"partida"</span>; y las opcionales como
              <b>"lugar", "tipología", "precio"</b>
              y <b>"unidades"</b>.
            </p>
          </div>
          <div class="put-bottom">
            <div class="info-box">
              <p><b>Si tienes alguna pregunta o necesitas asistencia en el proceso, estamos aquí para ayudarte.</b></p>
              <p>Contáctanos en <a href="mailto:info@diipai.io"><b>info@diipai.io</b></a> o <a
                  href="tel:+34649314748"><b>+34 649 314 748</b></a></p>
              <p>¡Gracias por unirte a nosotros!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import vueDropzone from 'vue2-dropzone-vue3'

export default {
  data() {
    return {
      taskId: null,
      validationErrors: null,
      uploadError: null,
      loading: false,
      hideUpload: false,
      canProcess: false,
      progress: 0,
      interval: null,
      showFormatHelp: false,
      dropzoneOptions: {
        url: '/client-area/database',
        maxFilesize: 256,
        acceptedFiles: null,
        maxFiles: 1,
        acceptedFiles: '.xlsx,.csv,.xls',
        chunking: false,
        chunkSize: 1000000,
        parallelChunkUploads: false,
        autoProcessQueue: false,
        dictDefaultMessage: '<i class="icon-agregar-documento add-doc-icon"></i><br>Arrastrar archivo o seleccionar archivo',
        headers: { 'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content },
        params: function params(files, xhr, chunk) { return { uuid: files[0].upload.uuid }; },
        init: function () {
          this.on('addedfile', function (file) {
            while (this.files.length > this.options.maxFiles) this.removeFile(this.files[0])
          })
        },
      }
    }
  },
  components: {
    vueDropzone,
  },
  mounted() {
    this.checkProcessing();
  },
  methods: {
    startUpload() {
      this.$refs.myVueDropzone.processQueue()
    },
    handleFileAdded() {
      this.canProcess = true;
    },
    handleUploading() {
      // this.loading = true;
    },
    handleProgress(file, progress, bytesSent) {
      this.canProcess = false;
      if (progress == 100) {
        this.loading = true;
      }
    },
    handleError(file, message, xhr) {
      if (typeof message === 'object') {
        this.validationErrors = message.errors;
      } else {
        this.validationErrors = {
          file: ["Se ha producido un error. Por favor, vuelva a intentarlo"]
        }
      }
      if (this.$refs.myVueDropzone) {
        this.$refs.myVueDropzone.removeFile(file)
      }
      this.loading = false;
      this.hideUpload = false;
      this.canProcess = true;
    },
    uploadSuccess(ev) {
      this.taskId = ev.upload.uuid
      //this.progress = 2;
      this.startProgressUpdates();
    },
    startProgressUpdates() {
      window.Echo.channel(`database.${this.taskId}`)
        .listen('.DatabaseProgress', (e) => {
          this.progress = e.process_status
          if (this.progress === 100) {
            this.stopProgressUpdates();
          }
        })
        .listen('.DatabaseFailed', (e) => {
          this.loading = false;
          this.hideUpload = false;
          this.stopProgressUpdates();
          this.uploadId = null;
          this.progress = 0;
          this.taskId = null;
          this.validationErrors = {
            file: ["No se ha podido procesar la base de datos. Por favor, vuelva a intentarlo."]
          };
          this.$refs.myVueDropzone.removeAllFiles()
        });
    },
    stopProgressUpdates() {
      clearInterval(this.interval)
    },
    checkProcessing() {
      axios.get('/client-area/database/check-processing').then(response => {
        if (response.data.database !== null) {
          this.taskId = response.data.database.process_id;
          this.progress = response.data.database.process_status;
          this.hideUpload = true;
          this.startProgressUpdates();
        }
      })
    },
  },
}
</script>
