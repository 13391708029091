<template>
  <div class="card card-body card-small-centered">
    <h1>Subir presupuesto<a href="" class="budget-help-btn" @click.prevent="showFormatHelp = true">Consultar formatos y
        ayuda</a></h1>
    <div :class="{ 'dropzone-disabled': (taskId !== null || loading) }">
      <vue-dropzone v-if="!hideUpload" ref="myVueDropzone" id="dropzone" @click="handleDropzoneClick"
        @vdropzone-success="uploadSuccess" @vdropzone-error="handleError" :useCustomSlot="false"
        :options="dropzoneOptions">
        <i class="icon-usuario"></i></vue-dropzone>
    </div>
    <div class="alert alert-danger mt-3" v-if="validationErrors && validationErrors.file && uploadId === null">{{
      validationErrors.file[0]
      }}</div>
    <div v-if="taskId === null && !loading">
      <form @submit.prevent="uploadDatabase" v-if="uploadId">
        <div class="form-group form-group-mb">
          <input type="text" id="title-input" v-model="title" class="form-control"
            :class="{ 'is-invalid': validationErrors && validationErrors.title }"
            placeholder="Nombre del presupuesto" />
          <div class="invalid-feedback" v-if="validationErrors && validationErrors.title">{{ validationErrors.title[0]
            }}</div>
        </div>

        <div class="form-group">
          <div class="threshold-container">
            <label for="threshold-input">Umbral</label>
            <div class="threshold">
              <Slider v-model="threshold" />
            </div>
            <button class="help-btn" type="button" @click="showThresholdHelp = true">?</button>
          </div>


        </div>
        <div class="form-group">
          <div class="database-select-container"
            :class="{ 'is-invalid': validationErrors && validationErrors.databases }">
            <label>Bases de datos</label>
            <button class="help-btn" type="button"
              v-tooltip.top="'Escoge el orden de preferencia para completar el presupuesto con el mayor número de partidas'">?</button>
            <div class="databases-select">
              <div class="database-list">
                <label>Disponibles <small>&nbsp;</small></label>
                <draggable class="list-group" :list="availableDatabases" :sort="false" group="databases" itemKey="name">
                  <template #item="{ element, index }">
                    <div class="list-group-item" @click="selectDatabase(element)">{{ element.name }}</div>
                  </template>
                </draggable>
              </div>
              <div class="sep">&#xbb;</div>
              <div class="database-list selected">
                <label>Seleccionadas <small>Ordenadas por prioridad</small></label>
                <div class="empty" v-if="selectedDatabases.length === 0">
                  Arrastra aquí las bases de datos que quieras seleccionar (máximo 2)
                </div>
                <draggable class="list-group" :list="selectedDatabases" group="databases" itemKey="name">
                  <template #item="{ element, index }">
                    <div class="list-group-item"><span>{{ index + 1 }}</span> {{ element.name }}</div>
                  </template>
                </draggable>
              </div>
            </div>
          </div>
          <div class="invalid-feedback" v-if="validationErrors && validationErrors.databases">{{
            validationErrors.databases[0]
            }}</div>
        </div>

        <div class="alert alert-danger mt-3" v-if="validationErrors && validationErrors.file">{{
          validationErrors.file[0]
          }}</div>
        <div class="row mt-5">
          <div class="col-md-6">
            <div class="estimated-cost">
              <label>Coste estimado</label>
              <div v-if="price">{{ Number(price).toLocaleString('es-ES') }} €</div>
              <div v-if="price === null"><i class="icon-cargando icon-loading"></i></div>
            </div>
          </div>
          <div class="col-md-6">
            <button class="btn btn-primary card-primary-button" type="submit">Procesar</button>

          </div>
        </div>

      </form>
    </div>
    <div v-else-if="(taskId !== null || loading)">
      <i class="icon-cargando icon-loading"></i>
      <div class="progress-container">
        <progress max="100" :value="progress"></progress>
        <div class="progress-num">{{ progress }}%</div>
      </div>
      <p class="text-center text-muted">Tu archivo está siendo procesado por nuestra inteligencia artificial. <br>¡Será
        solo
        un momento!</p>

    </div>
    <div v-else>
      Presupuesto subido
      <a :href="'/client-area/budgets/' + budgetCode">Ver resultados</a>
    </div>
    <a v-if="uploadId === null && showDatabaseButton" :href="databaseUrl" class="btn btn-secondary">Actualizar base de
      datos</a>
    <div class="side-help" :class="{ 'show': showThresholdHelp }" @click.self="showThresholdHelp = false">
      <div class="card">
        <div class="card-table-header">
          <div class="title">Umbral
          </div>
          <div class="right"><button @click="showThresholdHelp = false" class="btn-secondary btn-close"><i
                class="icon-close"></i></button></div>
        </div>
        <div class="card-body">
          <p>El umbral indica la probabilidad con la que quieres que enlacemos las partidas del presupuesto con las de
            la base de datos.</p>
          <p>Guía de rangos de probabilidad entre la similitud de dos partidas:</p>
          <div class="table-container">
            <table>
              <thead>
                <tr>
                  <th class="border-bottom">Umbral</th>
                  <th class="border-left border-bottom">
                    <span class="color-primary">Probabilidad</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border-bottom">80-100</td>
                  <td class="border-left border-bottom">Probabilidad muy alta</td>
                </tr>
                <tr>
                  <td class="border-bottom">72-80</td>
                  <td class="border-left border-bottom">Probabilidad alta</td>
                </tr>
                <tr>
                  <td class="border-bottom">62-72</td>
                  <td class="border-left border-bottom">Probabilidad intermedia</td>
                </tr>
                <tr>
                  <td>Menos de 60</td>
                  <td class="border-left">Probabilidad baja</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p><i>*Ten en cuenta que facturaremos solamente por las partidas que enlacemos, por lo que un menor umbral
              tendrá un mayor coste</i></p>
        </div>
      </div>
    </div>
    <div class="side-help" :class="{ 'show': showFormatHelp }" @click.self="showFormatHelp = false">
      <div class="card">
        <div class="card-table-header">
          <div class="title">¡Bienvendio de nuevo!
          </div>
          <div class="right"><button @click="showFormatHelp = false" class="btn-secondary btn-close"><i
                class="icon-close"></i></button></div>
        </div>
        <div class="card-body d-flex flex-column">
          <div>
            <p>Sube el presupuesto que deseas estudiar y presiona en procesar.</p>
            <div class="info-box formats">
              <div><i class="icon-archivo-csv"></i><i class="icon-archivo-excel"></i></div>
              <div class="desc">Formatos admitidos<div>CSV y EXCEL</div>
              </div>
              <img src="/images/flecha.png">
            </div>
            <p>El archivo debe incluir, como mínimo, <b>una columna con la descripción de las partidas</b>.</p>
            <p><i>*Opcionalmente, puede incluir más columnas, como ID que la identifique, unidades y/o mediciones.</i>
            </p>
            <div class="table-container">
              <table>
                <thead>
                  <tr>
                    <th class="border-bottom">
                      <span class="color-primary">partida</span>
                    </th>
                    <th class="border-left border-bottom">
                      id
                    </th>
                    <th class="border-left border-bottom">
                      unidades
                    </th>
                    <th class="border-left border-bottom">
                      mediciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border-bottom"></td>
                    <td class="border-left border-bottom">&nbsp;</td>
                    <td class="border-left border-bottom">&nbsp;</td>
                    <td class="border-left border-bottom">&nbsp;</td>
                  </tr>
                  <tr>
                    <td class="border-bottom"></td>
                    <td class="border-left border-bottom">&nbsp;</td>
                    <td class="border-left border-bottom">&nbsp;</td>
                    <td class="border-left border-bottom">&nbsp;</td>
                  </tr>
                  <tr>
                    <td class="border-bottom"></td>
                    <td class="border-left border-bottom">&nbsp;</td>
                    <td class="border-left border-bottom">&nbsp;</td>
                    <td class="border-left border-bottom">&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>La columna de descripción debe nombrarse como <span class="color-primary">"partida"</span>; y las
              opcionales para su identificación como <b>"id", "unidades"</b> y <b>"mediciones"</b>.</p>
          </div>
          <div class="put-bottom">
            <div class="info-box">
              <p><b>Si tienes alguna pregunta o necesitas asistencia en el proceso, estamos aquí para ayudarte.</b></p>
              <p>Contáctanos en <a href="mailto:info@diipai.io"><b>info@diipai.io</b></a> o <a
                  href="tel:+34649314748"><b>+34 649 314 748</b></a></p>
              <p>¡Gracias por unirte a nosotros!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import vueDropzone from 'vue2-dropzone-vue3'
import Slider from '@vueform/slider'
import draggable from "vuedraggable";

export default {
  props: ['databaseUrl'],
  data() {
    return {
      taskId: null,
      validationErrors: null,
      uploadError: null,
      progress: 0,
      interval: null,
      uploadId: null,
      fileName: null,
      budgetCode: null,
      title: '',
      price: null,
      threshold: 80,
      hideUpload: false,
      showDatabaseButton: true,
      loading: false,
      timeLimitTimeout: null,
      showThresholdHelp: false,
      showFormatHelp: false,
      availableDatabases: [
        {
          name: 'Base de datos cliente',
          value: 'company'
        }
        //{
        //  name: 'Itec - Bedec (Castellano)',
        //  value: 'ITEC_esp'
        //},
        //{
        //  name: 'Itec - Bedec (Català)',
        //  value: 'ITEC_cat'
        //}
      ],
      selectedDatabases: [],
      dropzoneOptions: {
        url: '/client-area/budgets/file',
        // thumbnailWidth: 150,
        maxFilesize: 1000000,
        // acceptedFiles: null,
        maxFiles: 1,
        acceptedFiles: '.xlsx,.csv,.xls',
        chunking: false,
        chunkSize: 2000000,
        parallelChunkUploads: false,
        dictDefaultMessage: '<i class="icon-agregar-documento add-doc-icon"></i><br>Arrastrar archivo o seleccionar archivo',
        headers: { 'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content },
        params: function params(files, xhr, chunk) { return { uuid: files[0].upload.uuid }; },
        init: function () {
          this.on('addedfile', function (file) {
            while (this.files.length > this.options.maxFiles) this.removeFile(this.files[0])
          })
        },
      }
    }
  },
  watch: {
    selectedDatabases: {
      handler: function(newValue, oldValue) {
        if (newValue.length > 2) {
          this.availableDatabases.push(this.selectedDatabases.pop());
        }
      },
      deep: true
    }
  },
  components: {
    vueDropzone,
    Slider,
    draggable
  },
  mounted() {
    this.checkProcessing();
  },
  methods: {
    selectDatabase(database) {
      const index = this.availableDatabases.findIndex(db => db.value === database.value);
      if (index !== -1) {
        this.availableDatabases.splice(index, 1);
        this.selectedDatabases.push(database);
      }
    },
    uploadSuccess(ev) {
      this.price = null;
      this.fileName = ev.upload.filename;
      this.uploadId = ev.upload.uuid;
      this.$refs.myVueDropzone.disable()
      this.validationErrors = null
      this.getEstimatedPrice(this.uploadId)
    },
    getEstimatedPrice(uploadId) {
      axios.get(`/client-area/budgets/estimated-price/${uploadId}`).then(response => {
        this.price = response.data.price
      })
    },
    handleError(file, message, xhr) {
      if (typeof message === 'object') {
        this.validationErrors = message.errors;
      } else {
        this.validationErrors = {
          file: ["Se ha producido un error. Por favor, vuelva a intentarlo"]
        }
      }
      this.$refs.myVueDropzone.removeFile(file)
      this.loading = false;
    },
    handleDropzoneClick() {
      if (this.uploadId !== null) {
        this.$refs.myVueDropzone.removeAllFiles()
        this.uploadId = null
        this.$refs.myVueDropzone.enable()
      }
    },
    startProgressUpdates() {
      //this.progress = 2;
      window.Echo.channel(`budget.${this.taskId}`)
        .listen('.BudgetProgress', (e) => {
          this.progress = e.process_status
          if (this.progress === 100) {
            this.stopProgressUpdates();
            window.location.replace('/client-area/budgets/' + this.budgetCode);
          }
        }).listen('.BudgetFailed', (e) => {
          this.hideUpload = false;
          this.$refs.myVueDropzone.removeAllFiles()
          this.$refs.myVueDropzone.enable()
          this.stopProgressUpdates();
          this.uploadId = null;
          this.progress = 0;
          this.taskId = null;
          this.validationErrors = {
            file: ["No se ha podido procesar el presupuesto. Por favor, vuelva a intentarlo."]
          };
          if (this.timeLimitTimeout !== null) {
            clearTimeout(this.timeLimitTimeout);
          }
        });
      this.timeLimitTimeout = setTimeout(() => {
        this.validationErrors = {
          file: ["Se ha superado el tiempo de espera. Por favor, vuelva a intentarlo"]
        }
        this.taskId = null;
      }, 900000);
    },
    stopProgressUpdates() {
      clearInterval(this.interval)
    },
    checkProcessing() {
      axios.get('/client-area/budgets/check-processing').then(response => {
        if (response.data.budget) {
          this.showDatabaseButton = false;
        }
      })
    },
    async uploadDatabase() {
      this.loading = true;
      const formData = new FormData()
      formData.append('file', this.uploadId)
      formData.append('title', this.title)
      formData.append('threshold_accuracy', this.threshold)
      formData.append('databases', this.selectedDatabases.map(db => db.value))
      axios({
        method: 'post',
        url: '/client-area/budgets',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.taskId = response.data.taskId
          this.budgetCode = response.data.code
          this.startProgressUpdates();
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.validationErrors = error.response.data.errors
          } else {
            this.uploadError = error.message
          }
        }).finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
