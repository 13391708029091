import axios from 'axios';

import 'bootstrap/dist/js/bootstrap';

// import { gdprCookieNotice } from './cookies.js'

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.interceptors.request.use(function (config) {
    var token = document.head.querySelector('meta[name="csrf-token"]');

    if (token) {
        config.headers['X-CSRF-TOKEN'] = token.content;
    }

    return config;
});


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allow your team to quickly build robust real-time web applications.
 */

import './echo';

// gdprCookieNotice({
//     locale: 'es', //This is the default value
//     timeout: 200, //Time until the cookie bar appears
//     expiration: 60, //This is the default value, in days
//     // domain: '.budquo.com', //If you run the same cookie notice on all subdomains, define the main domain starting with a .
//     implicit: false, //Accept cookies on scroll
//     statement: 'https://www.budquo.com/cookies-policy', //Link to your cookie statement page
//     performance: [], //Cookies in the performance category.
//     analytics: [], //Cookies in the analytics category.
//     marketing: [] //Cookies in the marketing category.
// });