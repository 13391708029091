<template>
  <div class="card">
    <div class="card-table-header">
      <div class="title">
        Histórico
        <input class="form-control search-table-top" placeholder="Filtrar por nombre o nº..." @input="getBudgets(1)"
          v-model="filters.query">
        <VueDatePicker v-model="filters.date" locale="es" range cancelText="Cancelar" selectText="Seleccionar"
          :enable-time-picker="false" @update:model-value="getBudgets(1)" format="dd/MM/yyyy"
          placeholder="Filtrar por fecha">
        </VueDatePicker>
      </div>
      <div class=" right" v-if="paginatedData">Mostrando {{ paginatedData.data.length }} de un total de {{
        paginatedData.meta.total }} presupuestos
      </div>
    </div>
    <table class="table" v-if="paginatedData">
      <thead>
        <tr>
          <th scope="col">Nº presupuesto</th>
          <th scope="col">Nombre presupuesto</th>
          <th scope="col">Umbral</th>
          <!-- <th scope="col">Estado</th> -->
          <th scope="col">Partidas encontradas</th>
          <th scope="col">Fecha presupuesto</th>
          <th scope="col">Usuario</th>
          <th scope="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="budget in paginatedData.data" :key="budget.code">
          <td scope="row">{{ budget.code }}</td>
          <td>{{ budget.title }}</td>
          <td>{{ budget.threshold }}%</td>
          <td><span class="found-perc">{{ budget.total_processed ? Math.floor((budget.total_found /
              budget.total_processed) * 100) : 0
              }}%</span> <span class="found-detail">{{ budget.total_found }}<span class="sep">/</span>{{
              budget.total_processed }}</span></td>
          <td>{{ formatDate(budget.created_at) }}</td>
          <td>{{ budget.user_name }}</td>
          <td>
            <template v-if="budget.process_status === 100">
              <a :href="detailLink(budget.code)" class="btn btn-primary action-button">Ver detalle</a>
            </template>
            <div class="budget-table-loading" v-if="budget.process_status < 100">
              <i class="icon-cargando icon-loading"></i> <span>Procesando ({{ budget.process_status }}%)</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-center" v-if="paginatedData">
      <ul class="pagination">
        <li class="page-item" :class="{'disabled': !paginatedData.prev_page_url}">
          <a class="page-link" href="#" aria-label="Previous"
            @click.prevent="getBudgets(paginatedData.meta.current_page - 1)">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only"></span>
          </a>
        </li>
        <li v-for="page in paginatedData.meta.last_page" :key="page"
          :class="{ 'active': page === paginatedData.meta.current_page}">
          <a class="page-link" href="#" @click.prevent="getBudgets(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{'disabled': !paginatedData.next_page_url}">
          <a class="page-link" href="#" aria-label="Next"
            @click.prevent="getBudgets(paginatedData.meta.current_page + 1)">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only"></span>
          </a>
        </li>
      </ul>
    </div>
    <Loader ref="loading"></Loader>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Loader from './Loader.vue';

export default {
  components: { VueDatePicker, Loader },
  data() {
    return {
      budgets: [],
      paginatedData: null,
      filters: {
        query: null,
        date: null
      }
    }
  },
  mounted() {
    this.listenForUpdates();
    this.getBudgets();
  },
  methods: {
    listenForUpdates() {
      window.Echo.channel(`budgets`)
        .listen('.BudgetProgress', (e) => {
          const index = this.paginatedData.data.findIndex(x => x.process_id === e.process_id);
          if (index >= 0) {
            this.paginatedData.data[index].process_status = e.process_status;
            this.paginatedData.data[index].total_found = e.total_found;
            this.paginatedData.data[index].total_processed = e.total_processed;
          }
        });
    },
    getBudgets(page = 1) {
      this.$refs.loading.show();
      axios.get('/client-area/budgets/list', {
        params: {
          page: page,
          query: this.filters.query,
          date: this.filters.date
        }
      })
        .then(response => {
          this.paginatedData = response.data;
        }).finally(() => {
          this.$refs.loading.hide();
        });
    },
    formatDate(date) {
      const d = new Date(date);
      const month = '' + (d.getMonth() + 1);
      const day = '' + d.getDate();
      const year = d.getFullYear();

      return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
    },
    detailLink(code) {
      return '/client-area/budgets/' + code
    }
  }
}
</script>
