<template>
  <div>
    <loader ref="loading"></loader>

    <Kpis :budget="budget"></Kpis>

    <div class="card" v-if="budget">

      <div class="card-table-header">
        <div class="title">
          {{ budget.budget.title }}
          <span class="date">{{
            formatDate(budget.budget.created_at)
            }}</span>

          <span class="date">{{
            budget.budget.threshold
            }}%</span>

        </div>
        <div class="right budget-right">

          <div class="count" v-if="budget">Mostrando {{ totalFilteredBudgetItems }} de {{
            budget.budgetData.kpis.TotalPartidasProcesadas }}
            partidas
          </div>

          <div class="dropdown">
            <button class="btn btn-primary btn-download dropdown-toggle" type="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              <i class="icon-datos-fuente"> </i>Descargar
            </button>
            <ul class="dropdown-menu">
              <li><a :href="`/client-area/budgets/${budget.budget.id}/excel?cols=${availableCols.join(',')}`"
                  class="dropdown-item">Excel</a></li>
              <li><a :href="`/client-area/budgets/${budget.budget.id}/csv?cols=${availableCols.join(',')}`"
                  class="dropdown-item">CSV</a></li>
            </ul>
          </div>

        </div>
      </div>

      <div class="filters">
        <input type="text" v-model="filters.query" class="form-control search-table-top"
          placeholder="Filtrar partidas por nombre o ID..." />
        <div class="btn-group btn-group-sm filter-found" role="group">
          <button type="button" class="btn btn-secondary" :class="{ 'btn-primary': filters.found === 'all' }"
            @click="filters.found = 'all'">Todas las partidas</button>
          <button type="button" class="btn btn-secondary" :class="{ 'btn-primary': filters.found === 'found' }"
            @click="filters.found = 'found'">Encontradas</button>
          <button type=" button" class="btn btn-secondary" :class="{ 'btn-primary': filters.found === 'not-found' }"
            @click="filters.found = 'not-found'">No encontradas</button>
        </div>
        <div class="filter-probability">
          <label>Probabilidad</label>
          <Slider v-model="filters.probability" />
        </div>
      </div>

      <div class="table-legend">
        <div><span class="legend-budget"></span> Información del Presupuesto</div>
        <div><span class="legend-database"></span> Información de la Base de datos</div>
      </div>

      <div class="budget-table">

        <div class="btn-group columns-selector">
          <button class="btn btn-secondary dropdown-toggle btn-columns float-right" type="button"
            id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside"
            aria-expanded="false">
            <i class="icon-columns"></i>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuClickableInside">
            <li v-for="col of availableCols">
              <div class="form-check" :class="cols[col].class">
                <input class="form-check-input" type="checkbox" :value="col" v-model="selectedCols" :id="col">
                <label class="form-check-label" :for="col">
                  {{ cols[col].label }}
                </label>
              </div>
            </li>
          </ul>
        </div>

        <DataTable v-if="budget" size="small" dataKey="key" :value="filteredBudgetItems" scrollable
          columnResizeMode="fit" :class="{ 'table-sm': selectedCols.length > 5 || smallScreen }" showGridlines
          scrollHeight="calc(100vh)" :virtualScrollerOptions="{ itemSize: itemSize }" :rowClass="rowClass"
          @rowSelect="onRowSelect" selectionMode="single">
          <Column v-for="(col, colIndex) in selectedColsOrdered" :field="col" :header="cols[col].label"
            :style="'width: ' + ((selectedCols.length > 5 || smallScreen) ? cols[col].width.small : cols[col].width.default)"
            :key="col" :class="cols[col].class">
            <template #body="slotProps">
              <template
                v-if="col === 'item_probability' && (!slotProps.data.isChapter || slotProps.data.item_probability)">
                <div class="probability" v-if="slotProps.data.item_probability !== '-'">
                  <span class="num">{{
                    Math.floor(slotProps.data.item_probability * 100) }}</span><span
                    :class="`percent percent-${probabilityClass(slotProps.data.item_probability)}`">%</span>
                </div>
              </template>
              <template
                v-else-if="(col === 'item_budget_text' || col === 'item_corpus_description') && !slotProps.data.isChapter">
                <div class="tooltip-bg"><span class="trimmed">{{ slotProps.data[col]
                    }}<div class="bg"></div></span>
                  <span class="tooltiptext">{{ slotProps.data[col]
                    }}</span>
                </div>
              </template>
              <template v-else-if="(col === 'item_corpus_id') && !slotProps.data.isChapter">
                <span :class="{ 'itec-corpus': slotProps.data['selected_database'].startsWith('ITEC_')}" class="tooltiptext">{{
                  slotProps.data[col] }}</span>
              </template>
              <template v-else-if="slotProps.data.isChapter && col == 'item_budget_medicion'"></template>
                <template v-else-if="slotProps.data.isChapter && col == 'item_budget_text'">
                  {{ slotProps.data.item_budget_resumen }}
                </template>
                <template v-else-if="slotProps.data.isChapter">
                  <span v-if="colIndex === 0" class="icon-arrow-chapter">&#9660;</span>
                  <span v-if="col === 'item_budget_units'" class="levels"><span
                      v-for="level in (slotProps.data.level + 1)"></span></span>
                  <div :title="slotProps.data[col]" v-if="col !== 'item_budget_units'">{{
                    slotProps.data[col] }}</div>
                </template>
                <template v-else>
                  <div :title=" slotProps.data[col]">{{ slotProps.data[col] }}</div>
                </template>
              </template>
          </Column>
          <Column field="actions" style="width: 150px;" class="actions-col">
            <template #body="slotProps">
              <div class="main-table-btns">
                <button @click="openOtherBudgetData(slotProps.data)" v-if="!slotProps.data.isChapter"
                  class="btn btn-primary action-button">Otras</button>
                <button @click="deleteBudgetItem($event, slotProps.data)" v-if="!slotProps.data.isChapter"
                  class="btn trash-button"><i class="icon-basura"></i></button>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <OtherBudgets ref="similarBudgets" :currentPartida='currentPartida' :smallScreen='smallScreen'
      :otherData='otherData' @selectOtherBudget="selectOtherBudget">
    </OtherBudgets>
    <ConfirmPopup></ConfirmPopup>
  </div>

</template>

<script>
import axios from 'axios'
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import Kpis from './budget-detail/Kpis.vue';
import OtherBudgets from './budget-detail/OtherBudgets.vue';
import Loader from './Loader.vue';
import Slider from '@vueform/slider'
import DataTable from 'primevue/datatable';
import TreeTable from 'primevue/treetable';
import Column from 'primevue/column';
import ConfirmPopup from 'primevue/confirmpopup';
// import { NodeService } from '@/service/NodeService';
import { useConfirm } from "primevue/useconfirm";

export default {
  components: {
    RecycleScroller,
    Loader,
    Slider,
    TreeTable,
    DataTable,
    Column,
    Kpis,
    OtherBudgets,
    ConfirmPopup
  },
  data() {
    return {
      currentPartida: null,
      budget: null,
      otherData: null,
      showOtherBudgets: false,
      expandedChapters: [],
      subItems: {},
      filters: {
        query: '',
        found: 'all',
        probability: [0, 100],
      },
      smallScreen: false,
      cols: {
        'item_budget_id': {
          class: 'budget id-col center',
          width: {
            default: 'auto',
            small: 'auto',
          },
          label: 'ID',
        },
        'item_budget_units': {
          class: 'budget units-col center',
          width: {
            default: 'auto',
            small: 'auto',
          },
          label: 'Unidades',
        },
        'item_budget_medicion': {
          class: 'budget medicion-col center',
          width: {
            default: 'auto',
            small: 'auto',
          },
          label: 'Medición',
        },
        'item_budget_text': {
          class: 'budget longtext',
          width: {
            default: '50%',
            small: '50%',
          },
          label: 'Descripción',
        },
        'item_corpus_units': {
          class: 'database units-col center',
          width: {
            default: 'auto',
            small: 'auto',
          },
          label: 'Unidades',
        },
        'item_corpus_description': {
          class: 'database longtext',
          width: {
            default: '50%',
            small: '50%',
          },
          label: 'Descripción',
        },
        'item_corpus_id': {
          class: 'database id-col center',
          width: {
            default: 'auto',
            small: 'auto',
          },
          label: 'ID',
        },
        'item_corpus_price': {
          class: 'database precio-col center',
          width: {
            default: 'auto',
            small: 'auto',
          },
          label: 'Precio',
        },
        'item_corpus_location': {
          class: 'database ubicacion-col center',
          width: {
            default: 'auto',
            small: 'auto',
          },
          label: 'Ubicación',
        },
        'item_corpus_typology': {
          class: 'database tipologia-col center',
          width: {
            default: 'auto',
            small: 'auto',
          },
          label: 'Tipología',
        },
        'item_corpus_medicion': {
          class: 'database tipologia-col center',
          width: {
            default: 'auto',
            small: 'auto',
          },
          label: 'Medición',
        },
        'item_corpus_year': {
          class: 'database tipologia-col center',
          width: {
            default: 'auto',
            small: 'auto',
          },
          label: 'Año',
        },
        'item_probability': {
          class: 'probabilidad-col center',
          width: {
            default: '140px',
            small: '90px',
          },
          label: 'Probabilidad',
        },
      },
      selectedCols: [
        'item_budget_id', 'item_budget_text', 'item_corpus_description', 'item_corpus_id', 'item_probability'
      ]
    }
  },
  props: ['budgetCode'],
  mounted() {
    this.getBudgetData();
    this.$nextTick(() => {
      this.onResize();
      window.addEventListener('resize', this.onResize);
    })
  },
  methods: {
    loadSubItems(parentItem) {
      const copied = Object.assign({}, parentItem);
      // copied.item_budget_id = 'abc'
      this.subItems[parentItem.key] = [copied];
    },
    onRowSelect(event) {
      if (event.data.isChapter) {
        this.toggleChapter(event.data.key);
      } else {
        // this.loadSubItems(event.data);
      }
    },
    toggleChapter(chapterKey) {
      const index = this.expandedChapters.indexOf(chapterKey);
      if (index > -1) {
        this.expandedChapters.splice(index, 1);
      } else {
        this.expandedChapters.push(chapterKey);
      }
    },
    listenScroll() {
      const mainContainer = document.getElementsByTagName('body')[0];
      const innerContainer = document.getElementsByClassName('p-datatable-wrapper')[0];
      let scrolling = false;

      innerContainer.addEventListener('wheel', (event) => {
        const delta = event.deltaY;
        const innerAtTop = mainContainer.scrollTop >= 414;

        if (!innerAtTop && delta > 0) {
          if (scrolling) return;
          scrolling = true;
          event.preventDefault();
          const innerContainerTop = innerContainer.getBoundingClientRect().top;
          const mainContainerTop = mainContainer.getBoundingClientRect().top;
          const targetScrollTop = mainContainer.scrollTop + (innerContainerTop - mainContainerTop);
          mainContainer.scrollTop = targetScrollTop;
          setTimeout(() => {
            scrolling = false;
          }, 1000)
        }

      });
    },
    openSimilarColumns(event) {
      this.$refs.op.toggle(event);
    },
    onResize() {
      this.smallScreen = window.innerWidth < 1400;
    },
    getBudgetData() {
      this.$refs.loading.show();
      axios.get(`/client-area/budgets/${this.budgetCode}/items`)
        .then(response => {
          this.budget = response.data

          if (this.budget.budgetData.detail) {
            alert(this.budget.budgetData.detail);
          }

          this.$nextTick(() => {
            this.listenScroll();
          })
        })
        .catch(error => {
          console.log(error)
        }).finally(() => {
          this.$refs.loading.hide();
        })
    },
    openOtherBudgetData(partida) {
      const itemCode = partida.item_budget_id;
      this.$refs.loading.show();
      this.currentPartida = partida;
      axios.get(`/client-area/budgets/${this.budgetCode}/${itemCode}/items`)
        .then(response => {
          if (!Array.isArray(response.data.data.detail)) {
            this.otherData = {
              detail: []
            }
          } else {
            this.otherData = response.data.data;
            this.otherData.detail.sort((a, b) => (b.item_probability > a.item_probability) ? 1 : -1);
          }
          this.$refs.similarBudgets.show();

        })
        .catch(error => {
          console.log(error)
        }).finally(() => {
          this.$refs.loading.hide();
        })
    },
    deleteBudgetItem(event, partida) {
      this.$confirm.require({
        target: event.currentTarget,
        message: 'Quieres eliminar esta partida?',
        icon: 'pi pi-info-circle',
        rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
        acceptClass: 'p-button-danger p-button-sm',
        rejectLabel: 'Cancelar',
        acceptLabel: 'Eliminar',
        accept: () => {
          this.$refs.loading.show();
          axios.delete(`/client-area/budgets/${this.budgetCode}/${partida.item_budget_id}`)
            .then(response => {
              this.getBudgetData();
            })
            .catch(error => {
              console.log(error)
            }).finally(() => {
              // this.$refs.loading.hide();
            })
        },
        reject: () => {

        }
      });

    },
    selectOtherBudget(newPartida) {
      this.$refs.loading.show();
      axios.post(`/client-area/budgets/${this.budgetCode}/${this.currentPartida.item_budget_id}/items`, {
        current: this.currentPartida,
        new: newPartida
      })
        .then(response => {
          this.getBudgetData();
        })
        .catch(error => {
          console.log(error)
        }).finally(() => {
          // this.$refs.loading.hide();
        })
    },
    probabilityClass(probability) {
      if (probability >= 0.8) return 'high';
      if (probability >= 0.6) return 'medium';
      return 'low';
    },
    rowClass(data) {
      if (data.isChapter) {
        return [`chapter level-${data.level}`, {'collapsed': !this.expandedChapters.includes(data.key)}];
      } else if (data.isSubItem) {
        return ['sub-item'];
      }
      return;
    },
    formatDate(date) {
      const d = new Date(date);
      const month = '' + (d.getMonth() + 1);
      const day = '' + d.getDate();
      const year = d.getFullYear();

      return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
    },
  },
  computed: {
    filteredBudgetItems() {
      const normalize = str => str.toLocaleLowerCase('en-US').normalize('NFD').replace(/[\u0300-\u036f]/g, "")

      const probabilityFilter = item => {
        if (item.data.item_probability == undefined) return true;
        if (this.filters.probability[0] === null || this.filters.probability[0] === '' || item.data.item_probability === '-') {
          return true
        }
        return item.data.item_probability >= (this.filters.probability[0] / 100)
      }

      const probabilityLowerFilter = item => {
        if (item.data.item_probability == undefined) return true;
        if (this.filters.probability[1] === null || this.filters.probability[1] === '' || item.data.item_probability === '-') {
          return true
        }
        return item.data.item_probability <= (this.filters.probability[1] / 100)
      }

      const foundFilter = item => {
        if (this.filters.found === 'found') {
          return item.data.item_corpus_id !== '-';
        } else if (this.filters.found === 'not-found') {
          return item.data.item_corpus_id === '-';
        }
        return true;
      }

      const queryMatchFilter = item => {
        const queryFilter = normalize(this.filters.query);
        return normalize(item.data.item_budget_text).includes(queryFilter) ||
          normalize(item.data.item_corpus_description).includes(queryFilter) ||
          normalize(item.data.item_budget_id.toString()).includes(queryFilter) ||
          normalize(item.data.item_corpus_id.toString()).includes(queryFilter)
      }

      const filterItems = (items, level = 0) => {
        return items.reduce((acc, item) => {
          if (item.children) {
            const filteredChildren = filterItems(item.children, level + 1);
            if (filteredChildren.length > 0) {
              item.data.level = level;
              acc.push({
                ...item,
                children: !this.expandedChapters.includes(item.key) ? [] : filteredChildren
              });
            }
          } else if (item.children === undefined && queryMatchFilter(item) && probabilityFilter(item) && probabilityLowerFilter(item) && foundFilter(item)) {
            acc.push(item);
          }
          return acc;
        }, []);
      }

      const plainItems = items => {
        return items.reduce((acc, item) => {
          item.data.key = item.key;
          if (item.children) {
            item.data.isChapter = true;
            return acc.concat(item.data).concat(plainItems(item.children));
          }
          return acc.concat(item.data);
        }, []);
      }

      const res = plainItems(filterItems(this.budget.budgetData.items));

      for (let subItemKey of Object.keys(this.subItems)) {
        const index = res.findIndex(item => item.item_budget_id === subItemKey);
        if (index !== -1) {
          res.splice(index + 1, 0, ...this.subItems[subItemKey].map(item => ({ ...item, isSubItem: true })));
        }
      }

      return res;
    },

    availableCols() {
      if (!this.budget || !this.budget.budgetData.items.length) {
        return []
      }

      const getFirstBudgetItem = item => {
        return item.children ? getFirstBudgetItem(item.children[0]) : item
      }

      return Object.keys(this.cols).filter(key => getFirstBudgetItem(this.budget.budgetData.items[0]).data.hasOwnProperty(key))
    },
    selectedColsOrdered() {
      return this.availableCols.filter(key => this.selectedCols.includes(key))
    },
    itemSize() {
      return 73.6
      if (this.smallScreen || this.selectedCols.length > 5) {
        return 82
      } else {
        return 103
      }
    },
    totalFilteredBudgetItems() {
      return this.filteredBudgetItems.filter(item => !item.isChapter).length
    }
  },

}
</script>
