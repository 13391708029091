<template>
  <div class="global-loader" :class="{ 'show': innerShow }">
    <div><i class="icon-cargando icon-loading"></i></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      innerShow: false,
    }
  },

  methods: {
    show() {
      this.innerShow = true;
    },
    hide() {
      this.innerShow = false;
    },
  }
}
</script>
