import './bootstrap';
import { createApp } from 'vue';
import DatabaseUpload from './components/DatabaseUpload.vue';
import BudgetUpload from './components/BudgetUpload.vue';
import BudgetTable from './components/BudgetTable.vue';
import BudgetsList from './components/BudgetsList.vue';
import Billing from './components/Billing.vue';
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/aura-light-green/theme.css'
import Tooltip from 'primevue/tooltip';
import ConfirmationService from 'primevue/confirmationservice';

const app = createApp({});
app.use(PrimeVue, {
    locale: {
        apply: 'Aplicar',
        clear: 'Limpiar'
    }
});

app.config.globalProperties.$filters = {
    str_limit(value, size) {
        if (!value) return '';
        value = value.toString();

        if (value.length <= size) {
            return value;
        }
        return value.substr(0, size) + '...';
    }
}


app.directive('tooltip', Tooltip);
app.use(ConfirmationService);

app.component('DatabaseUpload', DatabaseUpload);
app.component('BudgetUpload', BudgetUpload);
app.component('BudgetTable', BudgetTable);
app.component('BudgetsList', BudgetsList);
app.component('Billing', Billing);

app.mount("#app");