<template>
    <div class="row kpis-row" v-if="budget">
        <div class="col-md-3">
            <div class="kpi">
                <div class="icon"><i class="icon-doc-magnifying-glass-in-converted"></i></div>
                <div class="num">{{ budget.budgetData.kpis.PartidasEncontradas }}</div>
                <div class="label">Partidas <br>encontradas</div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="kpi">
                <div class="icon"><i class="icon-line-space"></i></div>
                <div class="num">{{ budget.budgetData.kpis.TotalPartidasProcesadas }}</div>
                <div class="label">Total <br>partidas
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="kpi">
                <div class="icon with-pie">
                    <div class="pie animate pie-bg no-round" style="--p:100;--c:#d6e9f0;--b:14px;--w: 100%;"></div>
                    <div class="pie animate no-round"
                        :style="'--p:' + (budget.budgetData.kpis.PercentPartidasEncontradas * 100).toFixed(0) + ';--c:#438fa6;--b:14px;--w: 100%;--c:#438fa6;'">
                    </div>
                </div>
                <div class="num">{{ Math.floor(budget.budgetData.kpis.PercentPartidasEncontradas * 100) }}%</div>
                <div class="label">Porcentaje partidas encontradas</div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="kpi">
                <div class="icon with-pie">
                    <div class="pie animate pie-bg no-round" style="--p:100;--c:#d6e9f0;--b:14px;--w: 100%;"></div>
                    <div class="pie animate no-round"
                        :style="'--p:' + (budget.budgetData.kpis.ProbabilidadMedia * 100).toFixed(0) + ';--c:#438fa6;--b:14px;--w: 100%;--c:#438fa6;'">
                    </div>
                </div>
                <div class="num">{{ Math.floor(budget.budgetData.kpis.ProbabilidadMedia * 100) }}%</div>
                <div class="label">Probabilidad<br>media</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['budget']
}
</script>